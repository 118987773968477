* {
  box-sizing: border-box;
  font-family: "Cedarville Cursive", cursive;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.heart-shape {
  position: relative;
  width: 200px;
  height: 200px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: rgba(250, 184, 66, 1);
}
.heart-shape:before,
.heart-shape:after {
  position: absolute;
  width: 200px;
  height: 200px;
  content: "";
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  background-color: rgba(250, 184, 66, 1);
}
.heart-shape:before {
  bottom: 0px;
  left: -100px;
}
.heart-shape:after {
  top: -100px;
  right: 0px;
}

button {
  background-color: transparent;
  border: none;
}

.heart {
  fill: red;
  position: relative;
  top: 5px;
  width: 50px;
  animation: pulse 1s ease infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

.wrapper {
  max-width: 600px;
}

.question {
  width: 100vw;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: pink;
  /* display: flex !important; */
  justify-content: center;
  /* align-items: center; */
  padding: 21px;
  border: 20px solid black;
  border-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='0%25' stop-color='%23cffffe' /%3E%3Cstop offset='25%25' stop-color='%23f9f7d9' /%3E%3Cstop offset='50%25' stop-color='%23fce2ce' /%3E%3Cstop offset='100%25' stop-color='%23ffc1f3' /%3E%3C/linearGradient%3E %3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='3'/%3E %3C/svg%3E")
    1;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-top: 0px; */
}

button {
  padding-top: 20px;
  cursor: pointer;
}
input {
  height: 60px;
  width: 100%;
  border-radius: 30px;
  border: none;
  padding: 10px;
  font-size: 18px;
  background-color: #ffeff2;
  color: #ff0000;
}

p {
  font-size: 30px;
  color: #ff0000;
  text-align: center;
}

.canvas {
  width: 100vw;
  height: 100vh;
  background-color: pink;
}
